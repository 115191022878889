import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { ConvertcsvjsonService } from 'src/app/common/services/convertcsvjson.service';
import { AddContentComponent } from '../add-content/add-content.component';
import { ChoosefiletypeComponent } from './choosefiletype/choosefiletype.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uploadfile',
  templateUrl: './uploadfile.component.html',
  styleUrls: ['./uploadfile.component.scss']
})
export class UploadfileComponent implements OnInit {

  highlyttoggle: boolean = false;
  currentRoute: string;
  linkForm: FormGroup;
  selectedSocialInfo: any;
  selectedMemberCount: any;
  profileIconArrayLength: any
  fileNme: any;
  constructor(public service: ConvertcsvjsonService, private message: MessagehandlingService, private api: ApiService, public dialog: MatDialog, public dialogRef: MatDialogRef<UploadfileComponent>, private router: Router, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: { block: any }) {
    if (this.data) {
      console.log(this.data);
      
      sessionStorage.setItem('linkData', JSON.stringify(this.data.block))
    }
    this.currentRoute = window.location.pathname
    this.linkForm = this.fb.group({
      title: '',
      file_name: '',
    })
  }

  ngOnInit(): void {
    let file = this.service.getImage()
    if (file) {
      this.fileNme = file.name
    } else {
      this.fileNme = 'File'
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
    this.dialog.open(AddContentComponent, {
      panelClass: ['cmn_modal', 'modal_lg'],
      width: '1066px',
    });
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }

  fileType(): void {
    this.dialogRef.close();
    this.dialog.open(ChoosefiletypeComponent, {
      panelClass: ['cmn_modal', 'modal_sm'],
      width: '390px',
      data: {
        route: 'add'
      }
    });
  }

  previewLink() {
    if (this.data?.block?.base_url==null) {
      window.open(environment.links+ this.fileNme)
    }
    if (this.data?.block?.base_url!=null) {
      window.open(this.data?.block?.base_url + this.fileNme)
    } else {
      let link: any = this.fileNme.slice(0, 5);
      if (link == "https" || link == "http:") {
        window.open(this.fileNme)
      } else {
        window.open("https://" + this.fileNme)
      }

    }
  }

  // previewLink() {
  //   if (this.data.block && this.data.block.base_url !== null && this.data.block.base_url !== undefined) {
  //     const baseUrl = this.data.block.base_url;
  //     const link = baseUrl.endsWith('/') ? baseUrl + this.content : baseUrl + '/' + this.content;
  //     window.open(link);
  //   } else {
  //     let link = this.content.slice(0, 5);
  //     if (link === "https" || link === "http:") {
  //       window.open(this.content);
  //     } else {
  //       window.open("https://" + this.content);
  //     }
  //   }
  // }
  


  submitForm() {
    let data1: any = sessionStorage.getItem('linkData')
    let data = JSON.parse(data1)
    let file = this.service.getImage()
    if (window.location.pathname === '/profile' || window.location.pathname === '/profile/content') {
      this.profileIconArrayLength = sessionStorage.getItem('profileIconArrayLength')
      if (
        !this.linkForm.get('title')?.value

      ) {
        this.message.errorMessage('Please fill in all required fields.', 2000);
        return;
      }
      let formData = new FormData();
      formData.append('row_order', this.profileIconArrayLength + 1)
      formData.append('title', this.linkForm.value.title)
      formData.append('file_name', file)
      formData.append('slug', data.slug)
      formData.append('active', '')
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.updateLink(formData)
    }
    if (window.location.pathname === '/members') {
      if (this.selectedMemberCount > 0) {
        if (
          !this.linkForm.get('title')?.value

        ) {
          this.message.errorMessage('Please fill in all required fields.', 2000);
          return;
        }
        let formData = new FormData
        formData.append('title', this.linkForm.value.title)
        formData.append('file_name', file)
        formData.append('slug', data.slug)
        formData.append('active', '')
        formData.append('type', 'primary')
        formData.append('user_id', sessionStorage.getItem('userIdArray') || '')
        this.api.saveLinksForMultipleProfile(formData).subscribe((res: any) => {
          this.dialog.closeAll()
          this.message.sucessMessage(res.msg, 2000)
          this.selectedMemberCount == 0
        })
      }
    }
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }


  updateLink(formData: any) {
    let data1: any = sessionStorage.getItem('linkData')
    let data = JSON.parse(data1)
    this.api.updateLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${data?.slug} successfully added to card`, 2000)
      this.dialog.closeAll();
      this.router.navigate(['/profile/content'])
    })
  }


}
