// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl :'https://api.b-intouch.me/team',
  // 'https://staging.b-intouch.me/team',
  baseurl:'https://api.b-intouch.me/api',
  // 'https://staging.b-intouch.me/api'
 userImage : 'https://api.b-intouch.me/public/uploads/user_images/',
 socialRectIconsGrey:'https://api.b-intouch.me/public/uploads/service_icons_v2/grey/',
 socialRectIcons: 'https://api.b-intouch.me/public/uploads/service_icons_v2/',     // for rectangle social icons
 socialRoundIcons:'https://api.b-intouch.me/public/uploads/service_icons_v2/round/',    // for round social icons
 qrlBaseUrl:'https://api.b-intouch.me/profile',
 activateDeviceBaseUrl:'https://api.b-intouch.me/api/activate_nfc',
 deviceListBaseUrl:'https://api.b-intouch.me/public/uploads/user_qrcode/',
 qrDownloadBaseLink : 'https://api.b-intouch.me/team/download_popcode?user_id=',
 socailIcons : 'https://api.b-intouch.me/public/uploads/user_files/',
 faqUploadBaseUrl:'https://api.b-intouch.me/public/uploads/tutorials/',
 links: 'https://api.b-intouch.me/public/uploads/user_files/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
