<div class="dialog_head position-relative">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
        <button (click)="back()"
            class="btn backbtn lh-1 border-0 fs13 text_secondary px-1 py-0 d-flex align-items-center justify-content-center">
            <img class="me-2" src="assets/images/backbtn.svg">
            <span class="ps-1">Back</span>
        </button>
        <button class="btn p-1 border-0 modal-close position-static d-flex align-items-center justify-content-center"
            (click)="closeDialog()"><img src="assets/images/modal-cross.svg"></button>
    </div>
    <!-- <h5 *ngIf="currentRoute=='/profile'" class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">Add
        {{selectedSocialInfo?.name}} link to
        1 profiles</h5>

    <h5 *ngIf="currentRoute!='/profile'" class="mb-0 mt-3 text-center text_secondary fw-bold lh-1">Add
        {{selectedSocialInfo?.name}} link to
        {{selectedMemberCount}} profiles</h5> -->
    <h5 class="mb-1 mt-3 text-center text_secondary fw-bold lh-1">{{selectedSocialInfo.name}} link</h5>

    <!-- <h5 class="mb-0 mt-3 text-center text_secondary fw-bold lh-1"> WhatsApp</h5> -->
    <button class="btn deleteBtn border-0" (click)="deleteIcon(selectedSocialInfo.id,selectedSocialInfo.slug)"><img
        src="assets/images/deleteicn.svg" alt=""></button>
</div>
<mat-dialog-content class="p-0 m-0" data-simplebar>
    <div class="content_sec">
        <!-- logo sec -->
        <div class="top-sec d-flex align-items-center justify-content-center flex-column">
            <div class="icon-wrap"><img src="assets/images/watsapp-icon.png"></div>
            <p class="fs11 text_gray mt-2 mb-0 lh-1">{{selectedSocialInfo.name}}</p>
        </div>
        <div class="dropdown addprofile_dropdown">
            <button
                class="btn d-flex fs11 align-items-center btn_xl rounded-pill text_secondary greyBorder mx-auto py-2 px-3 mt-2"
                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="me-2 d-flex align-items-center justify-content-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8.393" height="8.393" viewBox="0 0 8.393 8.393">
                        <path id="Path_34" data-name="Path 34" d="M13.393,9.8H9.8v3.6H8.6V9.8H5V8.6H8.6V5H9.8V8.6h3.6Z"
                            transform="translate(-5 -5)" fill="#4d4e58" />
                    </svg>
                </i>
                Add to other member
            </button>
            <div class="dropdown-menu border-0" aria-labelledby="dropdownMenuButton1" (click)="stopClose($event)">
                <div class="searchbox position-relative mb-3 ">
                    <input type="text" class="form-control rounded-5 border-0 shadow-none" placeholder="Search…"  [(ngModel)]="searchText"
                        >
                    <span class="searchIcon position-absolute d-flex align-items-center justify-content-center"><img
                            src="assets/images/search_icon.svg" /></span>
                </div>

                <!-- <a class="selectall btn text_secondary ms-3 border-0 fs11 fontMed p-0 mb-3 text-decoration-underline"
                    (click)="selectAllProfile()">Select All</a> -->
                    <mat-checkbox [(ngModel)]="selectAllCheckbox" (change)="selectAllProfile($event)"
                    class="custom_check fs12 fontMed letterspacingfull checkBg ms-2">Select All
                  </mat-checkbox>
                <div class="scroll_content pt-1 px-3" style="max-height: 177px;" data-simplebar>

                    <div *ngFor="let prof of profileData | custompipe:searchText  let profIndex=index"
                        class="filter-item d-flex align-items-center">
                        <ng-container *ngIf="prof.user_id !== userInfo.user_id">
                        <mat-checkbox class="custom_check flex-shrink-0" [checked]="prof.checked"
                            (change)="checkMat($event,profIndex,prof)"></mat-checkbox>
                        <div
                            class="user-img flex-shrink-0 fs12 font fw-bold text-white d-flex align-items-center justify-content-center">
                            <img  *ngIf="prof.photo!=''"    class="fit_img" [src]="profileImageUrl+ prof?.photo" alt="">
                           
                            <img  *ngIf="prof.photo==''"    class="img-fluid" src="assets/images/userdummywithbg.png" alt="">
                        </div>
                        <p class="text_secondary m-0 fs12">{{prof.first_name}}{{prof.last_name!=''? prof.last_name:''}}</p>
                    </ng-container>
                    </div>


                </div>
                <button
                    class="btn commonBtn rounded-pill py-0 fs14 w-100 text-white fw-normal shadow-none disabledBtn mt-3"
                    (click)="closeFilter()" [ngClass]="{'disabledBtn' : selectedProfileCount ==0 }">Add to {{selectedProfileCount}} member</button>
            </div>
        </div>
        <div class="padding-content pt-0">
            <!-- form content -->
            <form class="" [formGroup]="whatsappForm">
                <div class="toggle-sec d-flex align-items-center justify-content-between pt-4 pb-3 mb-1">
                    <div class="customSlideToggle rightText">
                        <mat-slide-toggle class="fs11 fontMed"  formControlName="highlight" [checked]="highlyttoggle"
                            (change)="highlightbtn($event)">
                            Highlight</mat-slide-toggle>
                    </div>
                    <p class="text_primary text-decoration-underline fontMed fs11 mb-0" (click)="previewLink()">Preview Link</p>
                </div>
                <div class="inputwrap position-relative mb-3">
                    <!-- <input class="form-control fs14 h46 inputBg rounded-3 border-0 text-black fontMed ps-5" type="text"  (keypress)="numberOnly($event)"
                    (countryChange)="onCountryChange($event)"  formControlName="dial_code" ng2TelInput (intlTelInputObject)="telInputObject($event)" 
                    placeholder="Enter Number" /> -->
                    <input class="form-control fs14 h46 inputBg rounded-3 border-0 text-black fontMed ps-5" type="text"
                    (countryChange)="onCountryChange($event)"
                   formControlName="content" ng2TelInput (intlTelInputObject)="telInputObject($event)"
                   placeholder="Enter Number" (input)="onInputChange($event)" (keyup)="formatPhoneNumber()" />
                </div> 
                <p class="fs14 text_gray mb-4 pt-1 ps-2 pb-1">{{selectedSocialInfo?.base_url}}<span
                    class="text_primary">{{content}}</span></p>               
                <div class="inputwrap title d-flex align-items-center justify-content-between h46 inputBg rounded-3">
                    <label class="fs14 text_gray fontMed mb-0">Title</label>
                    <input class="form-control fs14 inputBg h46 rounded-3 pe-0 border-0 text-black fontMed text-end shadow-none" formControlName='title'>
                </div>
                <div *ngIf="selectedSocialInfo.highlight==1 || highlyttoggle==true" class="inputwrap description inputBg rounded-3 mb-4">
                    <label class="fs14 text_gray fontMed mb-2 w-100 lh-1" for="description">Description</label>
                    <textarea formControlName="description"
                        class="fs14 inputBg rounded-3 p-0 border-0 text-black w-100 shadow-none"
                        id="description">Check out my trip to Greece’s new post!</textarea>
                </div>

                <button (click)="submitForm()" type="submit"
                    class="btn  btn-primary commonBtn rounded-pill fs17 w-100  shadow-none"
                    [disabled]="!(whatsappForm.get('highlight')?.dirty|| whatsappForm.get('dial_code')?.dirty|| whatsappForm.get('title')?.dirty || whatsappForm.get('description')?.dirty||  whatsappForm.get('country_code')?.dirty)">Update
                    link</button>
            </form>
        </div>
    </div>
</mat-dialog-content>

<!-- Username info popup -->
<div class="modal fade" id="userbameInfo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="userbameInfoLabel" aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-0 border-0 rounded-4">
            <div class="p-3 modal-body">
                <h2 class="fs17 fontMed mb-3 text-center">How to get your {{selectedSocialInfo.name}} username</h2>
                <p class="fs13 mb-0 pb-1 text_secondary text-center">{{selectedSocialInfo?.tips}}</p>
            </div>

            <div class="modal-footer p-2">
                <button type="button" class="btn btngotit border-0 fs17 fontMed p-0 my-1 mx-auto"
                    data-bs-dismiss="modal" style="color: #498BE5;">Got it!</button>
            </div>
        </div>
    </div>
</div>